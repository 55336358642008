import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import EditarModalMenu from './editarMenuModal';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import EditarModalSuplement from './editarSuplementsModal';


export default function Suplement(props) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
  

  }, []);


  const handleClose = () => {
    setVisible(false);
  }
  const handleUpdate = () => {
    props.getSuplements();
  }


  const eliminarSuplement = () => {


      

    fetch(process.env.REACT_APP_API_URL + "/eliminarsuplement/" + props.suplement.id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        props.getSuplements();
      })
      .catch((error) => {
        console.log(error);
      });
    }

    return (
      <>
        <Card variant="outlined" sx={{ width: 320 }}>
          <Typography className="titleBox" level="h2" fontSize="md" sx={{ mb: 0.5 }}>
            {props.suplement.nombre}
          </Typography>
          <Typography className="tipusPlat" level="body2">Suplements</Typography>
          <IconButton
            variant="plain"
            color="neutral"
            size="sm"
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            onClick={eliminarSuplement}
          >
            <DeleteIcon style={{ color: "#e77878" }} />
          </IconButton>


          <Box sx={{ display: 'flex' }}>
            <div>
              <Typography level="body3">Preu:</Typography>
              <Typography fontSize="lg" fontWeight="lg">
                {props.suplement.precio}€
              </Typography>
            </div>
            <Button
              variant="solid"
              size="sm"
              color="primary"
              sx={{ ml: 'auto', fontWeight: 600 }}
              onClick={() => setVisible(true)}
            >
              Editar
            </Button>
          </Box>
        </Card>
        {
          visible && <EditarModalSuplement handleClose={handleClose} suplement={props.suplement} handleUpdate={handleUpdate} />
        }
     
      </>
    );
  }