/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react"
import Avatar from "@mui/joy/Avatar"
import Box from "@mui/joy/Box"
import Chip from "@mui/joy/Chip"
import Link from "@mui/joy/Link"
import Table from "@mui/joy/Table"
import Sheet from "@mui/joy/Sheet"
import Checkbox from "@mui/joy/Checkbox"
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton"
import Typography from "@mui/joy/Typography"
import { useEffect } from "react"
import { useState } from "react"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DeleteIcon from '@mui/icons-material/Delete';





function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}


export default function OrderTable() {

    useEffect(() => {
        getComandes();
    }, []);


    const getComandes = () => {
        fetch(process.env.REACT_APP_API_URL + "/comandes", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                //guarda unicamente las 30 ultimas comandas
                data = data.slice(Math.max(data.length - 50, 0))


                formatComandes(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const formatComandes = (data) => {
        let comandesFormatted = [];
        data.map((comanda) => {
            let comandaFormatted = {
                id: comanda.id,
                //delete from date last 5 characters
                date: comanda.data.substring(0, comanda.data.length - 7),
                status: comanda.status_pagament == "" ? "Pagament a l'entrega" : comanda.status_pagament,
                id_pagament: comanda.id_pagament,
                customer: {
                    initial: comanda.nom[0],
                    name: comanda.nom,
                    email: comanda.email,
                    telefon: comanda.telef,
                },
                entrega: comanda.entrega,
                infoEntrega: {
                    adresa: comanda.adresa,
                    cp: comanda.cp,
                    horaentrega: comanda.horaentrega,
                    observacions: comanda.observacions,
                },
                formapagament: comanda.formapagament,
                productes: JSON.parse(comanda.productes),

            };
            comandesFormatted.push(comandaFormatted);
        });
        setComandesFormatted(comandesFormatted);
    };

    const [comandesFormatted, setComandesFormatted] = useState([]);
    const [comandes, setComandes] = useState([]);
    const [order, setOrder] = React.useState("desc")
    const [selected, setSelected] = React.useState([])

    const eliminarComades = () => {
  

        let confirm = window.confirm("Estàs segur que vols eliminar les comandes seleccionades?");
        if (confirm) {
        
            selected.map((comanda) => {
                fetch(process.env.REACT_APP_API_URL + "/deletecomanda/" + comanda, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data);
                        getComandes();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
        }


    }

    return (


        <React.Fragment>
            {comandesFormatted && <>

                {selected.length > 0 &&
            
                    <Chip  className="chipDel" onClick={() => eliminarComades()}
                        variant="soft"
                        size="sm"
                        startDecorator={
                            <DeleteIcon style={{ color: "#a10e25", fontSize: "16px" }} />
                        }

                    >
                        <div >Eliminar comandes seleccionades</div>
                    </Chip>
               
                    
                }
                <Sheet
                    className="OrderTableContainer"
                    variant="outlined"
                    sx={{
                        width: "100%",
                        borderRadius: "md",
                        flex: 1,
                        overflow: "auto",
                        minHeight: 0
                    }}
                >


                    <Table
                        aria-labelledby="tableTitle"
                        stickyHeader
                        hoverRow
                        sx={{
                            "--TableCell-headBackground": theme =>
                                theme.vars.palette.background.level1,
                            "--Table-headerUnderlineThickness": "1px",
                            "--TableRow-hoverBackground": theme =>
                                theme.vars.palette.background.level1
                        }}
                    >

                        <thead>
                            <tr>
                                <th style={{ width: 28, textAlign: "center", padding: 12 }}>
                                    <Checkbox
                                        indeterminate={
                                            selected.length > 0 && selected.length !== comandesFormatted.length
                                        }
                                        checked={selected.length === comandesFormatted.length}
                                        onChange={event => {
                                            setSelected(
                                                event.target.checked ? comandesFormatted.map(row => row.id) : []
                                            )
                                        }}
                                        color={
                                            selected.length > 0 || selected.length === comandesFormatted.length
                                                ? "primary"
                                                : undefined
                                        }
                                        sx={{ verticalAlign: "text-bottom" }}
                                    />
                                </th>
                                <th style={{ width: 30, padding: 12 }}>
                                    <Link
                                        underline="none"
                                        color="primary"
                                        component="button"
                                        onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                                        fontWeight="lg"
                                        endDecorator={<i data-feather="arrow-down" />}
                                        sx={{
                                            "& svg": {
                                                transition: "0.2s",
                                                transform:
                                                    order === "desc" ? "rotate(0deg)" : "rotate(180deg)"
                                            }
                                        }}
                                    >
                                        ID
                                    </Link>
                                </th>
                                <th style={{ width: 120, padding: 12 }}>Data</th>
                                <th style={{ width: 120, padding: 12 }}>Status</th>
                                <th style={{ width: 220, padding: 12 }}>Client</th>
                                <th style={{ width: 180, padding: 12 }}>Dades de enviament</th>
                                <th style={{ width: 100, padding: 12 }}>Observacions</th>
                                <th style={{ width: 290, padding: 12 }}>Productes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {comandesFormatted && <>
                                {stableSort(comandesFormatted, getComparator(order, "id")).map(row => (
                                    <tr key={row.id}>
                                        <td style={{ textAlign: "center" }}>
                                            <Checkbox
                                                checked={selected.includes(row.id)}
                                                color={selected.includes(row.id) ? "primary" : undefined}
                                                onChange={event => {
                                                    setSelected(ids =>
                                                        event.target.checked
                                                            ? ids.concat(row.id)
                                                            : ids.filter(itemId => itemId !== row.id)
                                                    )
                                                }}
                                                slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                                                sx={{ verticalAlign: "text-bottom" }}
                                            />
                                        </td>
                                        <td>
                                            <Typography fontWeight="md">{row.id}</Typography>
                                        </td>
                                        <td>{row.date}</td>
                                        <td className={row.status} >
                                            <Chip
                                                variant="soft"
                                                size="sm"
                                                startDecorator={
                                                    {
                                                        pagat: <CheckIcon style={{ color: "#1c6731", fontSize: "16px" }} />,
                                                        error: <CloseIcon style={{ color: "#a10e25", fontSize: "16px" }} />,
                                                        pendent: <MoreHorizIcon style={{ color: "#333", fontSize: "16px" }} />,

                                                    }[row.status]
                                                }

                                            >
                                                <div className={row.status}>{row.status == "pagat" ? "Pagat" : row.status == "error" ? "Error" : row.status == "pendent" ? "Pendent" : "Pagament a l'entrega"}</div>
                                            </Chip>
                                            {row.id_pagament && <p className="minIDP">{row.id_pagament}</p>}
                                            {row.formapagament != 2 && <p className="minIDP ml8">{row.formapagament == 1 ? "efectiu/targeta" : row.formapagament == 3 ? "Xec Gourmet" : row.formapagament == 4 ? "Sodexo" : row.formapagament == 5 ? "Ticket Restaurant" : ""}</p>}
                                        </td>
                                        <td>
                                            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                                <Avatar size="sm">{row.customer.initial}</Avatar>
                                                <div>
                                                    <Typography
                                                        fontWeight="lg"
                                                        level="body3"
                                                        textColor="text.primary"
                                                    >
                                                        {row.customer.name}
                                                    </Typography>
                                                    <Typography level="body3">
                                                        {row.customer.email}
                                                    </Typography>
                                                    <Typography level="body3">
                                                        {row.customer.telefon}
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </td>
                                        <td>    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                            <Avatar size="sm">{row.entrega == 0 ? <StorefrontIcon style={{ color: "#333333", fontSize: "16px" }} /> : <DeliveryDiningIcon style={{ color: "#333333", fontSize: "16px" }} />}</Avatar>
                                            <div>
                                                <Typography
                                                    fontWeight="lg"
                                                    level="body3"
                                                    textColor="text.primary"
                                                >
                                                    {row.infoEntrega.adresa ? row.infoEntrega.adresa : "Recollida al local"}
                                                </Typography>
                                                <Typography level="body3">
                                                    {row.infoEntrega.cp}
                                                </Typography>
                                                <Typography level="body3">
                                                    {row.infoEntrega.horaentrega}
                                                </Typography>
                                            </div>
                                        </Box></td>
                                        <td>
                                            {row.infoEntrega.observacions}
                                        </td>
                                        <td className="productesRow">
                                            {
                                                row.productes.map((menus, index) => (
                                                    menus.map((producte, index) => (
                                                        <div className="producte" key={index}>
                                                            <div className="producteInfo">
                                                                <div className="producteInfoName">

                                                                {
                                                                    producte.name ?
                                                                        <p>{producte.name} ({producte.menu} Menu) (x{producte.quantity})</p>
                                                                        : 
                                                                        <p>{producte.nombre} x{producte.quantity}</p>
                                                                }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ))



                                            }

                                        </td>
                                    </tr>
                                ))}
                            </>}
                        </tbody>

                    </Table>
                </Sheet>
                <Box
                    className="Pagination-mobile"
                    sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
                >
                    <IconButton
                        aria-label="previous page"
                        variant="outlined"
                        color="neutral"
                        size="sm"
                    >
                        <i data-feather="arrow-left" />
                    </IconButton>
                    <Typography level="body2" mx="auto">
                        Page 1 of 10
                    </Typography>
                    <IconButton
                        aria-label="next page"
                        variant="outlined"
                        color="neutral"
                        size="sm"
                    >
                        <i data-feather="arrow-right" />
                    </IconButton>
                </Box>
            </>}
        </React.Fragment>
    )
}
