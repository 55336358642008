import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Textarea from '@mui/joy/Textarea';
import { FileUploader } from "react-drag-drop-files";
import NativeSelect from '@mui/material/NativeSelect';
import { useEffect } from 'react';

export default function ModalPlatEditar(props) {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [nom, setNom] = React.useState(null);
    const [tipus, setTipus] = React.useState(null);
    const [descripcio, setDescripcio] = React.useState(null);
    const [preu, setPreu] = React.useState(null);
    const [nomImatge, setNomImatge] = React.useState(null);
    const fileTypes = ["JPG"];

    useEffect(() => {
        setNom(props.infoPlat.nom);
        setTipus(props.infoPlat.tipus);
        setDescripcio(props.infoPlat.descripcio);
        setPreu(props.infoPlat.preu);
        setNomImatge(props.infoPlat.imatge);


    }, []);


    const handleChange = (file) => {

        //setFile(file.target.files[0]);
        let fileData = {
            name: file.name.split("."),
            file: file,
        }
        const formData = new FormData();
        formData.append('file', file);

        fetch(process.env.REACT_APP_API_URL + "/subirimagen", {
            method: "POST",

            body: formData,
        })

            .then((response) => response.json())
            .then((data) => {
            }
            );



        setFile(fileData);



    };



    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="neutral"

                onClick={() => setOpen(true)}
            >
                Edita el plat
            </Button>
            <Modal open={open} onClose={() => {
                setOpen(false);
                setNom(props.infoPlat.nom); 
                setTipus(props.infoPlat.tipus);
                setDescripcio(props.infoPlat.descripcio);
                setPreu(props.infoPlat.preu);
                setFile(null);
            }}>
                <ModalDialog className="modalPlat"
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        Editar el plat
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        Edita la informació del plat
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();

                            if (tipus == 0) {
                                alert("Selecciona un tipus de plat");
                                return;
                            }

                            let form = {
                                nom: nom,
                                tipus: tipus,
                                descripcio: descripcio,
                                preu: preu,
                                imatge: file !== null  ? file.name : nomImatge ? nomImatge : null,
                                id: props.infoPlat.id

                            }

                            fetch(process.env.REACT_APP_API_URL + "/editarplat", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(form),
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.succes) {
                                        props.refreshPlats();
                                        setOpen(false);
                                    } else {
                                        alert("Error al editar el plat");
                                    }
                                }
                                );




                            //     setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Nom</FormLabel>
                                <Input value={nom} onChange={(e) => setNom(e.target.value)} autoFocus required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Tipus de plat</FormLabel>
                                <NativeSelect
                                    defaultValue={tipus}
                                    onChange={(e) => setTipus(e.target.value)}
                                    required
                                    inputProps={{
                                        name: 'tipusplat',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={0}>Selecciona una opció</option>
                                    <option value={1}>Primer plat</option>
                                    <option value={2}>Segon plat</option>
                                    <option value={3}>Postres</option>
                                </NativeSelect>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Descripció</FormLabel>
                                <Textarea value={descripcio} onChange={(e) => setDescripcio(e.target.value)} minRows={2} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Puja una fotografia</FormLabel>
                                <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                                {
                                    file !== null ? <span className='uploadedTxt'>Fitxer seleccionat: {file.name[0]}</span> : nomImatge ? <span className='uploadedTxt'>{nomImatge}.jpg</span> : <span className='uploadedTxt'>No hi ha cap imatge </span>
                                }
                            </FormControl>
                         
                            <FormControl>
                                <FormLabel>Preu</FormLabel>
                                <Input
                                    value={preu}
                                    autoFocus
                                    required
                                    onChange={(e) => setPreu(e.target.value)}
                                />
                            </FormControl>

                            <Button type="submit">Editar plat</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}