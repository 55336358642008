
import LeftMenu from './components/menu.js';
import { useEffect } from 'react';
import { useState } from 'react';
import Button from '@mui/joy/Button';
import Add from '@mui/icons-material/Add';
import ModalSoretig from './components/addNouSorteigModal.js';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from "@mui/joy/Table"
const Sortejos = () => {
    const [sortejos, setSortejos] = useState([]);
    const [premiats, setPremiats] = useState([]);
    const [updated, setUpdated] = useState(false);


    useEffect(() => {

        fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
        })
            .then((response) => response.json())

            .then((data) => {
                if (data === true) {
                    getSortejos();
                    getPremiats();
                    
                }
                if (data === false) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    window.location.href = "/login";
                }
            }
            );

    }, [updated]);




    const getPremiats = () => {
        fetch(process.env.REACT_APP_API_URL + "/relpremis", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPremiats(data);
            }
            );
    }


    const getSortejos = () => {
        fetch(process.env.REACT_APP_API_URL + "/premis", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setSortejos(data);
            }
            );
    }
    const deleteSoretig = (id) => {

        if (!window.confirm("Estas segur que vols eliminar el sorteig?")) {
            return;
        }


        //"/deletecomanda/:id
        fetch(process.env.REACT_APP_API_URL + "/deletepremi/" + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                getSortejos();
            }
            );

    }





    return (
        <div className="menu-container">

            <LeftMenu />
            <div className='content'>
                <h1>Sortejos disponibles</h1>
                <ModalSoretig getAllSorteigs={getSortejos} />


                <div className='gridPlats'>
                    {sortejos.map((sorteig) => (

                        <Card variant="outlined" sx={{ width: 320 }}>
                            <Typography className="titleBox2" level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                                {sorteig.nombre}
                            </Typography>
                            <Typography className="tipusPlat" level="body2">x{sorteig.cantidad}</Typography>
                            <Typography className="descripcioPlat" level="body2" fontSize="sm" sx={{ mb: 1.5 }}>
                                {sorteig.descripcion}
                            </Typography>
                            <IconButton
                                variant="plain"
                                color="neutral"
                                size="sm"
                                sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
                            >
                                <DeleteIcon style={{ color: "#e77878" }} onClick={() => deleteSoretig(sorteig.id)} />
                            </IconButton>


                            <Box sx={{ display: 'flex' }}>
                                <div>
                                    <Typography className="minPerc" level="body3">Probabilitat per participació:</Typography>
                                    <Typography fontSize="lg" fontWeight="lg">
                                        {sorteig.porcentaje}%
                                    </Typography>
                                </div>

                            </Box>
                        </Card>
                    ))}



                </div>

                {
                    premiats.length > 0 && (
                        <>
                            <h4>Sortejos premiats</h4>
                            <Table
                                aria-labelledby="tableTitle"
                                stickyHeader
                                hoverRow
                                style={{ border: '1px solid black', borderRadius: '5px' }}
                            >
                                <thead style={{ backgroundColor: 'lightgray' }}>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>ID</td>
                                        <td style={{ fontWeight: 'bold' }}>Data Premiat</td>
                                        <td style={{ fontWeight: 'bold' }}>Email</td>
                                        <td style={{ fontWeight: 'bold' }}>Nom Premi</td>
                                        <td style={{ fontWeight: 'bold' }}>Utilitzat</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {premiats.map((premiat) => (
                                        <tr key={premiat.id} className={premiat.utilitzat === 1 ? 'tdDisabled' : ''}>
                                            <td>{premiat.id}</td>
                                            <td>
                                                {new Date(premiat.data_premi).toLocaleDateString('es-ES')}
                                            </td>
                                            <td>{premiat.email}</td>
                                            <td>{premiat.nom_premi}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={premiat.utilitzat === 1}
                                                    onChange={() => {
                                                        fetch(process.env.REACT_APP_API_URL + "/updatepremi/" + premiat.id, {
                                                            method: "PUT",
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                            },
                                                            body: JSON.stringify({ utilizat: premiat.utilitzat === 1 ? 0 : 1 }),
                                                        })
                                                            .then((response) => response.json())
                                                            .then((data) => {
                                                                getPremiats();
                                                            }
                                                            );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )
                }
            </div>

        </div>
    )
}
export default Sortejos