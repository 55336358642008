import { CartProvider, useCart } from "react-use-cart";
import { Link } from 'react-router-dom';
import { useState } from "react";
import AlergenPopUp from "./alergenPopUp";
import React from "react";
import { useEffect } from "react";

const Cart = () => {
    const [tipoTransporte, setTipoTransporte] = useState(0);
    const [precioEnvio, setPrecioEnvio] = useState(0);
    const [showAlergenPopUp, setShowAlergenPopUp] = useState(false);


    useEffect(() => {
        setCartMetadata({ shippingType: 0 });
    }, []);


    const {
        isEmpty,
        totalUniqueItems,
        items,
        shippingPrice,
        setCartMetadata,
        updateItemQuantity,
        removeItem,

    } = useCart();

    if (isEmpty) return <></>;

    let totalProducts = 0;
    let menus = [];
    let totalPriceFinal = 0;


    //totalProducts es el numero de platos que hay en el carrito y cantidades de cada uno
    items.forEach((item) => {

        if (item.minim === 1 && item.quantity <= 1) {
            updateItemQuantity(item.id, 2);
        }

        totalProducts += item.quantity;
    });

    //separaremos los platos por menu
    items.forEach((item) => {
        // console.log(item, "ITEM");
        //guarda el item en el menu correspondiente menus[item]
        menus[item.menu] = menus[item.menu] || [];
        //haz el push  dentro del menu tipo de plato
        menus[item.menu].push(item);
    });

    console.log("menus: ", menus);

    // guarda primers plats, segons plats, postres en un array para cada menu
    menus.forEach((menu) => {
        let primers = [];
        let segons = [];
        let postres = [];
        let totalPrice = 0;
        menu.forEach((plat) => {

            //haz el push  de plato por cada plat.quantity
            for (let i = 0; i < plat.quantity; i++) {
                if (plat.tipo === "Primer plat") primers.push({ "id": plat.id, "nombre": plat.name, "precio": plat.price, "tipo": plat.tipo, "menu": plat.menu });
                if (plat.tipo === "Segon plat") segons.push({ "id": plat.id, "nombre": plat.name, "precio": plat.price, "tipo": plat.tipo, "menu": plat.menu });
                if (plat.tipo === "Postre") postres.push({ "id": plat.id, "nombre": plat.name, "precio": plat.price, "tipo": plat.tipo, "menu": plat.menu });
            }
        });



        //por cada primero, segundo y postre genera un menu que costara 11.5€ y elimina los platos de los arrays
        let menuTemp = 0;
        while (primers.length > 0 && segons.length > 0 && postres.length > 0) {
            menuTemp++;
            primers.shift();
            segons.shift();
            postres.shift();
        }
        //    console.log("menus: ", menu, "primers: ", primers, "segons: ", segons, "postres: ", postres);

        //ahora total price es el precio de los menus 
        totalPrice = menuTemp * 11.5;

        //ahora añadimos el precio de los platos sueltos
        primers.forEach((primer) => {
            totalPrice += primer.precio;
        });
        segons.forEach((segon) => {
            totalPrice += segon.precio;
        });
        postres.forEach((postre) => {
            totalPrice += postre.precio;
        });

        totalPriceFinal += totalPrice;
    });



    const changeTipoTransporte = (tipo) => {
        setTipoTransporte(tipo);
        if (tipo === 0) {
            setPrecioEnvio(0);
            setCartMetadata({ shippingType: 0 });
        } else {
            setPrecioEnvio(2);
            setCartMetadata({ shippingType: 2 });
        }
    }
    const sortedItemsByMenu = items.reduce((acc, item) => {
        if (acc[item.menu]) {
            acc[item.menu].push(item);
        } else {
            acc[item.menu] = [item];
        }
        // Ahora ordenamos los elementos dentro de cada menú por tipo
        acc[item.menu].sort((a, b) => {
            const order = ["Primer plat", "Segon plat", "Postre"];
            return order.indexOf(a.tipo) - order.indexOf(b.tipo);
        });

        return acc;

    }, {});


    return (
        <div className="cartfloat">
            <div className="cartFloatHeader">
                <h1 className="cartFloatTitle" >La teva comanda</h1>
                <h1 className="cartFloatTitle">{totalPriceFinal + precioEnvio}€ </h1>
            </div>
            <div className="cartFloatBody">
                <span onClick={() => setShowAlergenPopUp(true)} className="alergens">
                    🛈 Info. al·lèrgens
                </span>
            </div>
          
                <div className="finComadaParent">
                    <Link className="aFinComanda" to={"/checkout"} ><button className="fincomanda">Finalitzar comanda</button></Link>
                    {
                /*
                    <div className="switcherTransp">
                        <div onClick={() => changeTipoTransporte(0)} className={tipoTransporte === 0 ? "selectedRecollida" : ""}>Recollida al local</div>
                        <div onClick={() => changeTipoTransporte(1)} className={tipoTransporte === 1 ? "selectedRecollida" : ""}>A domicili</div>
                    </div>
                     */
            }
                </div>
               
            <div className="cartFloatLista">
                <ul className="listacomandapadre">
                    {Object.entries(sortedItemsByMenu).map(([menu, menuItems]) => (
                        <React.Fragment key={menu}>
                            <li className="listamenu" key={`menu-${menu}`}>
                                {menu === '0' ? 'Plats de carta' : `Menu: ${menuItems[0].fecha}`}
                            </li>
                            {menuItems.map((item) => (
                                <li className="listacomanda" key={item.id}>
                                    <span className="quant">{item.quantity}</span> <span className="prova">{item.name}</span>
                                    <div className="buttonscart">
                                        <button className="botonresta" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                            -
                                        </button>
                                        <button className="botonsuma" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                            +
                                        </button>
                                        <span onClick={() => removeItem(item.id)} className="botonborrar material-symbols-outlined">
                                            delete
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </React.Fragment>
                    ))}
                    {
                        /*
                         {items.map((item) => (
                        <li className="listacomanda" key={item.id}>
                            <span className="quant">{item.quantity}</span> <span className="prova">{item.name}</span>
                            <div className="buttonscart">
                                <button className="botonresta"
                                    onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
                                >
                                    -
                                </button>
                                <button className="botonsuma"
                                    onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                                >
                                    +
                                </button>
                                <span onClick={() => removeItem(item.id)} className="botonborrar material-symbols-outlined">
                                    delete
                                </span>

                            </div>

                        </li>
                    ))}
                        */
                    }

                    {/*
                    {items.sort((a, b) => {
                        return a.menu > b.menu ? 1 : -1;
                    })
                        .map((item, index, array) => {
                            let currentMenu = item.menu;
                            let currentMenuFecha = item.fecha;
                            if (index === 0 || (index > 0 && currentMenu !== array[index - 1].menu)) {
                                return (
                                    <React.Fragment key={currentMenu}>
                                        <li className="listamenu" key={`menu-${currentMenu}`}>
                                        {currentMenu === 0 ? 'Plats Individuals' : `Menu: ${currentMenuFecha}`}
                                        </li>
                                        <li className="listacomanda" key={item.id}>
                                            <span className="quant">{item.quantity}</span>
                                            <span className="prova">{item.name}</span>
                                            <div className="buttonscart">
                                                <button className="botonresta"
                                                    onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                                    -
                                                </button>
                                                <button className="botonsuma"
                                                    onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                                    +
                                                </button>
                                                <span onClick={() => removeItem(item.id)}
                                                    className="botonborrar material-symbols-outlined">
                                                    delete
                                                </span>
                                            </div>
                                        </li>
                                    </React.Fragment>
                                );
                            } else {
                                return (
                                    <li className="listacomanda" key={item.id}>
                                        <span className="quant">{item.quantity}</span>
                                        <span className="prova">{item.name}</span>
                                        <div className="buttonscart">
                                            <button className="botonresta"
                                                onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                                -
                                            </button>
                                            <button className="botonsuma"
                                                onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                                +
                                            </button>
                                            <span onClick={() => removeItem(item.id)}
                                                className="botonborrar material-symbols-outlined">
                                                delete
                                            </span>
                                        </div>
                                    </li>
                                );
                            }
                        })}

                */}
                </ul>
                <div>
                    <div className="cartFloatFooter">
                        <div className="listacomandapadre">
                            <div className="cartFloatFooterSub">
                                {
                                    /*
                                <span className="subTotalT"><strong>Subtotal</strong></span>
                                <span className="subTotalTP"><strong>{totalPriceFinal}€</strong></span>

                              
                                

                                <span className="enviamentT">Despeses d'enviament</span>
                                <span className="enviamentP">{precioEnvio}€</span>
                                */
                                }
                                <span className="subTotalT"><strong>Total </strong></span>
                                <span className="subTotalTP"><strong>{totalPriceFinal + precioEnvio}€</strong></span>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAlergenPopUp && <AlergenPopUp onClose={() => setShowAlergenPopUp(false)} />}
        </div>
        /*
        <div className="cartfloat">
            <h1 className="titlecart" onClick={handleOpenSummary}>
                <div>  <span class=Name"material-symbols-outlined">
                    shopping_cart
                </span><span className="iconTotal"> {totalProducts} </span></div>
            </h1>{summary && <div>
                <ul className="listacomandapadre">
                    {items.map((item) => (
                        <li className="listacomanda" key={item.id}>
                            <span className="prova">{item.quantity} x {item.name}</span>
                            <div className="buttonscart">
                                <button className="botonresta"
                                    onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
                                >
                                    -
                                </button>
                                <button className="botonsuma"
                                    onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                                >
                                    +
                                </button>
                                <span onClick={() => removeItem(item.id)} className="botonborrar material-symbols-outlined">
                                    delete
                                </span>

                            </div>

                        </li>
                    ))}
                </ul>
                <p className="preciofinal">Total: <span className="preciofinalnumero">{totalPriceFinal}€ </span></p>
                <Link to={"/checkout"} ><button className="fincomanda">Finalitzar comanda</button></Link>
            </div>}
        </div>
        */
    );
}
export default Cart;
