import LeftMenu from './components/menu.js';
import { useEffect, useState } from 'react';
import Plat from './components/plat.js';
import ModalPlat from './components/addPLatModal.js';

const Plats = () => {
    const [plats, setPlats] = useState([]);
    const [filteredPlats, setFilteredPlats] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);
    const [showPrimero, setShowPrimero] = useState(false);
    const [showSegundo, setShowSegundo] = useState(false);
    const [showPostre, setShowPostre] = useState(false);


    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/comprobarAdmin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.getItem('token'),
                id: localStorage.getItem('id'),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data === true) {
                    getAllPlats();
                }
                if (data === false) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('id');
                    window.location.href = '/login';
                }
            });
    }, []);

    const getAllPlats = () => {
        fetch(process.env.REACT_APP_API_URL + '/allplats', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPlats(data);
            });
    };

    const handleEditClick = (productId) => {
        setEditingProductId(productId);
    };

    const handleEditModalClose = () => {
        setEditingProductId(null);
    };

    const filteredPlatsToRender = filteredPlats.length > 0 ? filteredPlats : plats;

    const renderedPlats = filteredPlatsToRender.map((plat) => {
        let infoPlat = {
            id: plat.id,
            nom: plat.nombre,
            preu: plat.precio,
            descripcio: plat.descripcion,
            imatge: plat.img,
            tipus: plat.tipus,
        };

         

        return (
            <Plat
                key={plat.id}
                getAllPlats={getAllPlats}
                infoPlat={infoPlat}
                onEditClick={handleEditClick}
                isEditing={editingProductId === plat.id}
            />
        );
    });

    return (
        <div className="menu-container">
            <LeftMenu />
            <div className="content">
                <h1>Plats</h1>
               
               
                <div className="inputPDF">
                    <p>Filtrar per plats</p>
                    
                    {
                        /*
                         <div className="checkboxes">
                        <label>
                            <input type="checkbox" name="primero" onChange={() => setShowPrimero(!showPrimero)} />
                            Primero
                        </label>
                        <label>
                            <input type="checkbox" name="segundo" onChange={() => setShowSegundo(!showSegundo)} />
                            Segundo
                        </label>
                        <label>
                            <input type="checkbox" name="postre" onChange={() => setShowPostre(!showPostre)} />
                            Postre
                        </label>
                    </div>
                    */
                }
                                                
                    <input
                        placeholder="Arròs caldós"
                        onChange={(e) => {
                            let value = e.target.value;
                            let filteredPlats = plats.filter((plat) => {
                                return plat.nombre.toLowerCase().includes(value.toLowerCase());
                            });
                            setFilteredPlats(filteredPlats);
                        }}
                    />
                </div>
                <ModalPlat getAllPlats={getAllPlats} onClose={handleEditModalClose} editingProductId={editingProductId} />
                <div className="gridPlats">{renderedPlats}</div>
            </div>
        </div>
    );
};

export default Plats;