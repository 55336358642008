import LeftMenu from "./components/menu"
import { useEffect } from 'react'
import { useState } from 'react'
import Table from "@mui/joy/Table"
import { Button } from "@mui/material"
import Input from "@mui/joy/Input"

const Opinions = () => {
    const [opinions, setOpinions] = useState([])
    const [textOpinions, setTextOpinions] = useState("")

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
        })
            .then((response) => response.json())

            .then((data) => {
                if (data === true) {
                    getOpinions();
                    getTextOpinions();
                }
                if (data === false) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    window.location.href = "/login";
                }
            }
            );

    }, [])

    const getOpinions = async () => {
        fetch(process.env.REACT_APP_API_URL + "/opinions", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setOpinions(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getTextOpinions = async () => {
        fetch(process.env.REACT_APP_API_URL + "/gettextopinions", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setTextOpinions(data[0].text);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const generarExcel = async () => {
        fetch(process.env.REACT_APP_API_URL + "/generarexcel", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let url = "https://feslatevacomanda.elracodelacigonya.com/opiniones/" + data.url;
                window.open(url, '_blank');


            })
            .catch((error) => {
                console.log(error);
            });
    };

    const buidarComentaris = async () => {
        fetch(process.env.REACT_APP_API_URL + "/buidarcomentaris", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                getOpinions();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const guardarTextOpinions = async () => {
        fetch(process.env.REACT_APP_API_URL + "/guardartextopinions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: 
                JSON.stringify({textOpinions})
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <>
            <div className="menu-container" >

                <LeftMenu />
                <div className='content'>
                    <h1>Opinions</h1>
                    <Button variant="contained" onClick={generarExcel} >Generar Excel</Button> &nbsp;&nbsp;&nbsp;
                    <Button onClick={buidarComentaris} variant="contained" color="error">Buidar Comentaris</Button>
                    <section style={{ width: '100%' }}>
                        <p>Text a mostrar a l'apartat opinions</p>
                        <div style={ {display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginRight: '10px'} }>
                            <Input autoFocus required onChange={(event) => setTextOpinions(event.target.value)} value={textOpinions} style={{ width: '100%' }} />
                            <Button variant="contained" onClick={() => { guardarTextOpinions() }} >Guardar</Button>
                        </div>

                    </section>
                    <br />
                    <br />
                    <Table
                        aria-labelledby="tableTitle"
                        stickyHeader
                        hoverRow
                        style={{ border: '1px solid black', borderRadius: '5px' }}
                    >
                        <thead style={{ backgroundColor: 'lightgray' }}>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>ID</td>
                                <td style={{ fontWeight: 'bold' }}>Nom</td>
                                <td style={{ fontWeight: 'bold' }}>Email</td>
                                <td style={{ fontWeight: 'bold' }}>Data</td>
                                <td style={{ fontWeight: 'bold' }}>Opinió</td>
                            </tr>
                        </thead>
                        <tbody>
                            {opinions.map((opinion) => (
                                <tr key={opinion.id}>
                                    <td>{opinion.id}</td>
                                    <td>{opinion.nombre}</td>
                                    <td>{opinion.email}</td>
                                    <td>{new Date(opinion.fecha).toLocaleDateString()}</td>
                                    <td>
                                        <a className="btnOpinio" href={"/admin/opinio/" + opinion.id}>Opinió</a>
                                    </td>
                                </tr>
                            ))}


                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}
export default Opinions