import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Select from 'react-select';
import { useEffect } from 'react';

export default function EditarModalSuplement(props) {
    const [open, setOpen] = React.useState(true);
    const [nombre, setNombre] = React.useState("");
    const [precio, setPrecio] = React.useState("");

    useEffect(() => {
        setNombre(props.suplement.nombre);
        setPrecio(props.suplement.precio);

        ;
    }, []);


    return (
        <React.Fragment>
         
                <Modal open={open} onClose={props.handleClose}>
                    <ModalDialog className="modalPlat"
                        aria-labelledby="basic-modal-dialog-title"
                        aria-describedby="basic-modal-dialog-description"
                        sx={{
                            maxWidth: 500,
                            overflowY: 'auto',  // Agregamos scroll vertical si el contenido excede la altura del modal
                            maxHeight: 'calc(100vh - 100px)'  // Limitamos la altura máxima del modal para evitar que se extienda más allá del viewport
                        }}
                    >
                        <Typography id="basic-modal-dialog-title" component="h2">
                            Editar suplement
                        </Typography>
                   
                        <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        </Typography>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                fetch(process.env.REACT_APP_API_URL + "/modificarsuplement", {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                    },
                                    
                                    body: JSON.stringify({
                                        nombre: nombre,
                                        precio: parseFloat(precio),
                                        id: props.suplement.id,
                                    }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        props.handleUpdate();
                                        props.handleClose();
                                    })  
                                    .catch((error) => {
                                        console.log(error);
                                    }
                                    );




                            }}
                        >
                            <Stack spacing={2}>
                                <FormControl>
                                    <FormLabel>Nom del suplement</FormLabel>
                                    <Input value={nombre} onChange={(event) => setNombre(event.target.value)} autoFocus required />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Preu</FormLabel>
                                    <Input value={precio} onChange={(event) => setPrecio(event.target.value)} autoFocus required />
                                </FormControl>
                                


                                <Button type="submit">Modificar suplement</Button>
                            </Stack>
                        </form> 
                    </ModalDialog>
                </Modal>
            
        </React.Fragment>
    );
}