const Compte = () => {
    return (
        <>
            <div>
                <div className="headerT">

                </div>
                <div className="divTop">
                    <span className="MMsubtitle">El Racó de la cigonya</span>
                    <h3>La meva compte</h3>
                    <br></br>

                </div>
                <div className="billetera">
                    <div className="ticket">
                       
                       
                    </div>
                </div>

                <br></br>
            </div>
        </>
    )
}
export default Compte