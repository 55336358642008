import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { useEffect } from 'react';
import Select from 'react-select';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ModalCarta(props) {
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const [minim, setMinim] = React.useState(null);
    const [plats, setPlats] = React.useState(null);


    useEffect(() => {

        getPlats();
    }, []);



    const getPlats = () => {
        fetch(process.env.REACT_APP_API_URL + "/plats", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setPlats(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <React.Fragment>
            {plats && <>
                <Button
                    variant="outlined"
                    color="neutral"
                    startDecorator={<Add />}
                    onClick={() => setOpen(true)}
                >
                    Agrega nou plat a carta
                </Button>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalDialog className="modalPlat"
                        aria-labelledby="basic-modal-dialog-title"
                        aria-describedby="basic-modal-dialog-description"
                        sx={{ maxWidth: 500 }}
                    >
                        <Typography id="basic-modal-dialog-title" component="h2">
                            Selecciona nous plats per la carta
                        </Typography>
                        <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                            Selecciona quins plats seran de carta y si son per minim dos persones
                        </Typography>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                     

                                fetch(process.env.REACT_APP_API_URL + "/modificarplatcarta", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({ id: selected, minim: minim }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        
                                            props.onRefresh();
                                            setSelected(null);
                                            setMinim(null);
                                            
                                            setOpen(false);
                                    
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });



                              //  setOpen(false);
                            }}
                        >
                            <Stack spacing={2}>

                                <FormControl>
                                    <FormLabel>Selecciona un plat</FormLabel>
                                    <Select className="selectorPlats"
                                        value={selected}
                                        onChange={setSelected}
                                        options={plats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={minim}
                                                onChange={(event) => setMinim(event.target.checked)}
                                                name="checkedB"

                                            />
                                        }
                                        label="Aquest plat sera com a minim per dos persones"
                                    />
                                </FormControl>

                                <Button type="submit">Crear plat de carta</Button>
                            </Stack>
                        </form>
                    </ModalDialog>
                </Modal>
            </>}
        </React.Fragment>
    );
}