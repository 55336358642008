import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const AlergenPopUp = ({ onClose }) => {
    return (
      <div className="popUp">
        <div className="popUpBox">
          <div className="popUpHeader">
            <p>Tens alguna al·lèrgia alimentària?</p>
            <div className="closeBTN" onClick={onClose}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="popUpBody2">
            <div className="popUpBody2Box">
              <p>
                Si tu o algú per a qui estàs demanant té una al·lèrgia o
                intolerància a algun aliment, truca al restaurant al<strong> <a href='tel:9389811
                01'>938 98 11
                01.</a></strong> No facis la teva comanda si no pots aconseguir la informació
                que necessites sobre al·lèrgens.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
    export default AlergenPopUp;