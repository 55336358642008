import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom';
const PagamentKo = () => {


    useEffect(() => {

        /*
                fetch(process.env.REACT_APP_API_URL + "/redsysko", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ paymentId: id }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data);
                    }
                    );
                    */
    }, []);

    let { id } = useParams();
    return (
        <div className="bgBox">
            <div className='boxMiddle'>
                <ErrorIcon style={{ fontSize: 100, color: "#D95E52" }} />
                <h1 className='koTitle'>Error amb el pagament</h1>
                <p className='okSub'>Número de transacció:<b></b> {id}</p>
                <div className='okSubBox'>
                    <p className='okSub'>Si creus que ha pogut ser un error. Pots contactar amb nosaltres al <a href='tel:938981101'>938 98 11 01</a></p>


                </div>
                <Link to={"/"} ><button className='okButton'>Tornar a la botiga</button></Link>
            </div>

        </div>
    );
};
export default PagamentKo;
