import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RatingIcons from './rating';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import Textarea from '@mui/joy/Textarea';
import Input from '@mui/joy/Input';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const steps = ["Ajudan's a millorar", "La teva informació"];

export default function HorizontalLinearStepper(props) {

    const { id } = useParams();

    useEffect(() => {

        if (props.type === '1') {

            fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
            })
                .then((response) => response.json())

                .then((data) => {
                    if (data === true) {
                        //es admin
                        getOpinionVisita();
                        setEsVisita(true);
                    }
                    if (data === false) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("id");
                        window.location.href = "/login";
                    }
                }
                );
        }
        gettextopinions();

    }, []);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [error, setError] = React.useState(false);
    const [loadedPremi, setLoadedPremi] = React.useState(false);
    const [esVisita, setEsVisita] = React.useState(false);
    const [formData, setFormData] = React.useState({
        local: 4,
        personal: 4,
        menu: 4,
        opinioLocal: '',
        nom: '',
        cognom: '',
        email: '',
        telefon: '',
        observacions: '',
    });
    const [premio, setPremio] = React.useState(false);
    const [textOpinions, setTextOpinions] = React.useState("");


    const [ratingValue, setRatingValue] = React.useState(2);



    const getOpinionVisita = async () => {


        fetch(process.env.REACT_APP_API_URL + "/opinions/" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())

            .then((data) => {

                let dataJson = JSON.parse(data[0].data);
                setFormData({
                    local: dataJson.local,
                    personal: dataJson.personal,
                    menu: dataJson.menu,
                    opinioLocal: dataJson.opinioLocal,
                    nom: dataJson.nom,
                    cognom: dataJson.cognom,
                    email: dataJson.email,
                    telefon: dataJson.telefon,
                    observacions: dataJson.observacions,
                });
            }
            );

    }
    const handleRatingChange = (id, newValue) => {

        const formDataKeyMap = {
            '1': 'local',
            '2': 'personal',
            '3': 'menu',
        };

        setRatingValue(newValue);
        setFormData({ ...formData, [formDataKeyMap[id]]: newValue });
    };


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if (activeStep === 1 && props.type !== '1') {
            //validamos si los campos nombre, apellido, email y telefono estan vacios
            if (formData.nom === '' || formData.cognom === '' || formData.email === '' || formData.telefon === '') {
                alert('Has d\'omplir els camps obligatoris');
                return;
            }



            //guardamos los datos en la BBDD
            fetch(process.env.REACT_APP_API_URL + "/afegiropinio", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.result === 'ok') {
                        setError(false);
                        setLoadedPremi(true);

                        if (data.data.premio !== null) {
                            setPremio(data.data.premio);
                        } else {
                            setPremio(false);
                        }

                    } else {
                        setError(true);
                    }
                })
                .catch((err) => console.log(err));


        } else if (activeStep === 1 && props.type === '1') {
            //redirect a la pagina /admin/opinions
            window.location.href = "/admin/opinions";


        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        setSkipped(newSkipped);
    };



    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const gettextopinions = async () => {
        fetch(process.env.REACT_APP_API_URL + "/gettextopinions", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setTextOpinions(data[0].text);
            })
            .catch((error) => {
                console.log(error);
            });
    }



    const handleReset = () => {
        setActiveStep(0);
    };


    return (
        <>
            <Typography variant="h3" component="h2" className='titleOpinansM'>
                {steps[activeStep]}
                <span className="stepTxt">El Raco de la Cigonya</span>
            </Typography>
            <p
                style={{textAlign: "center", fontSize:"13px"}}
            >{textOpinions}</p>
            <div className='stepBox'>

                <Box className="mainBoxSteps" sx={{ width: '100%', height: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            {
                                !error &&
                                <div className="stepContentBox thxBox">

                                    {
                                        !loadedPremi &&

                                        <div className='insideThx'>
                                            <div className='imgC'>
                                                <img style={{ textAlign: "center" }} height="150px" src="https://cdn-icons-png.flaticon.com/512/726/726271.png" alt="checked" />
                                            </div>
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                Cargant dades...
                                            </Typography>
                                        </div>
                                    }
                                    {
                                        !premio && loadedPremi &&

                                        <div className='insideThx'>
                                            <div className='imgC'>
                                                <img style={{ textAlign: "center" }} height="150px" src="https://cdn-icons-png.flaticon.com/512/4436/4436481.png" alt="checked" />
                                            </div>
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                Moltes gràcies per la teva opinió!
                                                NO has guanyat cap premi, però pots tornar a provar sort el proper mes.
                                            </Typography>
                                        </div>
                                    }

                                    {
                                        premio && loadedPremi &&

                                        <div className='insideThx'>
                                            <div className='imgC'>
                                                <img style={{ textAlign: "center" }} height="150px" src="https://cdn-icons-png.flaticon.com/512/3847/3847268.png" alt="checked" />
                                            </div>
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                Moltes felicitats! Has guanyat: <b> {premio.nombre} </b><br /><span className='felPremi'> Rebras mes informació al teu correu.</span>

                                            </Typography>
                                        </div>
                                    }

                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Link to={"/"} ><Button>Tornar</Button></Link>
                                    </Box>
                                </div>


                            }
                            {
                                error &&
                                <div className="stepContentBox thxBox">
                                    <div className='insideThx'>
                                        <div className='imgC'>
                                            <img style={{ textAlign: "center" }} height="150px" src="https://cdn-icons-png.flaticon.com/512/463/463612.png" alt="checked" />
                                        </div>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            Hi ha hagut un error, recorda que nomes pots fer una enquesta per mes, si no es el teu cas, torna a intentar-ho mes tard.

                                        </Typography>
                                    </div>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                        <Link to={"/"} ><Button>Tornar</Button></Link>
                                    </Box>
                                </div>
                            }

                        </React.Fragment>
                    ) : activeStep === 0 ? (
                        <React.Fragment>
                            <div className="stepContentBox">


                                <div className='firstAsk'>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Local
                                    </Typography>

                                    <RatingIcons id="1" onRatingChange={handleRatingChange} activeStep={activeStep} esVisita={esVisita} valor={formData.local} />
                                </div>
                                <div>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Personal
                                    </Typography>
                                    <RatingIcons id="2" onRatingChange={handleRatingChange} activeStep={activeStep} esVisita={esVisita} valor={formData.personal} />
                                </div>
                                <div>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Menú
                                    </Typography>
                                    <RatingIcons id="3" onRatingChange={handleRatingChange} activeStep={activeStep} esVisita={esVisita} valor={formData.menu} />
                                </div>
                                <div className='lastAsk'>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Què puc millorar?
                                    </Typography>
                                    <FormControl>
                                        <Textarea value={formData.opinioLocal} onChange={(e) => setFormData({ ...formData, opinioLocal: e.target.value })} placeholder="Deixa'ns la teva opinió" />
                                        <FormHelperText>*Opcional</FormHelperText>
                                    </FormControl>
                                </div>

                            </div>
                            <Box className="stepBtnBox" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Tornar
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />


                                <Button onClick={handleNext} variant="contained">
                                    {activeStep === steps.length - 1 ? 'Acabar' : 'Següent'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    ) : activeStep === 1 ? (
                        <React.Fragment>
                            <div className="stepContentBox">

                                <div className='firstAsk'>
                                    <span className='infoPers'>
                                        Ara et demanarem algunes dades personals per deixar constància de la teva opinió i participar en el sorteig.
                                        <br></br>No et preocupis, les teves dades estaran segures i només les utilitzarem amb aquest propòsit.
                                        <br></br>Recorda, rebràs un justificant al correu que ens proporciones més a baix per saber si has guanyat.
                                    </span>
                                </div>

                                <div className='boxInfoPersonal1'>
                                    <div>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            Nom
                                        </Typography>
                                        <Input value={formData.nom} onChange={(e) => setFormData({ ...formData, nom: e.target.value })} />
                                    </div>
                                    <div>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            Cognom
                                        </Typography>
                                        <Input value={formData.cognom} onChange={(e) => setFormData({ ...formData, cognom: e.target.value })} />
                                    </div>

                                </div>
                                <div className='boxInfoPersonal1'>

                                    <div>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            Telefon
                                        </Typography>
                                        <Input value={formData.telefon} onChange={(e) => setFormData({ ...formData, telefon: e.target.value })} />
                                    </div>
                                    <div>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            Email
                                        </Typography>
                                        <Input value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                    </div>
                                </div>
                                <div className='lastAsk'>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Vols afegir alguna cosa més?
                                    </Typography>
                                    <FormControl>
                                        <Textarea value={formData.observacions} onChange={(e) => setFormData({ ...formData, observacions: e.target.value })} placeholder="Deixa'ns la teva opinió" />
                                        <FormHelperText>*Opcional</FormHelperText>
                                    </FormControl>
                                </div>

                            </div>
                            <Box className="stepBtnBox" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Tornar
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />


                                <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Acabar' : 'Següent'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Tornar
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />


                                <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Acabar' : 'Següent'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </div>
        </>
    );
}