import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalPlatEditar from './editarPlatModal';


export default function Plat(props) {

    useEffect(() => {
       
    }, []);

    const deleteIcon = () => {
        fetch(process.env.REACT_APP_API_URL + "/deleteplat/" + props.infoPlat.id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                props.getAllPlats();
            }
            );
            
    }

    const refreshPlats = () => {
        props.getAllPlats();
    }


  return (
    <Card variant="outlined" sx={{ width: 320 }}>
      <Typography  className="titleBox" level="h2" fontSize="md" sx={{ mb: 0.5 }}>
        {props.infoPlat.nom}
      </Typography>
      <Typography className="tipusPlat" level="body2">{props.infoPlat.tipus === 1 ? "Primer" : props.infoPlat.tipus === 2 ? "Segon" : props.infoPlat.tipus === 3 ? "Postre" : "Sense determinar"}</Typography>
      <IconButton
        variant="plain"
        color="neutral"
        size="sm"
        sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
      >
        <DeleteIcon onClick={()=> deleteIcon()} style={{color:"#e77878"}}/>
      </IconButton>
      <AspectRatio minHeight="120px" maxHeight="200px" sx={{ my: 2 }}>
        <img
          src={props.infoPlat.imatge === ""  ? "https://feslatevacomanda.elracodelacigonya.com/fotos/defaultFood.jpg" : "https://feslatevacomanda.elracodelacigonya.com/fotos/"+props.infoPlat.imatge+".jpg"}
    
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Typography level="body3">Preu:</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            {props.infoPlat.preu}€
          </Typography>
        </div>
        <Button
          variant="solid"
          size="sm"
          color="primary"
          sx={{ ml: 'auto', fontWeight: 600 }}
        >
          <ModalPlatEditar infoPlat={props.infoPlat} refreshPlats={refreshPlats}/>
        </Button>
      </Box>
    </Card>
  );
}