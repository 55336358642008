
import LeftMenu from './components/menu.js';
import { useEffect } from 'react';
import { useState } from 'react';
import Suplement from './components/suplement.js';
import ModalSuplement from './components/addSuplement.js';



const Suplements = () => {

    const [suplements, setSuplements] = useState([]);

    useEffect(() => {

        fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
        })
            .then((response) => response.json())

            .then((data) => {
                if (data === true) {
                    getSuplements();
                }
                if (data === false) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    window.location.href = "/login";
                }
            }
            );



    }, []);


    const getSuplements = () => {
        fetch(process.env.REACT_APP_API_URL + "/getsuplements", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setSuplements(data);

            })
            .catch((error) => {
                console.log(error);
            });
    };







    return (
        <div className="menu-container" >

            <LeftMenu />
            <div className='content'>
                <h1>Suplements</h1>
                <ModalSuplement getSuplements={getSuplements} />
                <div className='gridPlats'>
                    {
                        suplements.map((suplement) => (
                            <Suplement key={suplement.id} getSuplements={getSuplements} suplement={suplement} />
                        ))
                    }
                </div>

            </div>

        </div>
    )
}
export default Suplements