import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


const CalendariPopUp = ({ updateSelectedHelp }) => {
    return (
        <div className="popUp">
            <div className="popUpBox">
                <div className="popUpHeader">
                    <p>Organitza el teu menú setmanal.</p>
                    <div className="closeBTN" onClick={() => updateSelectedHelp(null)}>
                        <IconButton  >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="popUpBody2">
                    <div className="popUpBody2Box">
                        <p>Organitza el teu menú setmanal. Tindràs disponible el menú setmanal el dilluns de la mateixa setmana a partir de les 12h.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalendariPopUp