import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';

import { useRef } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';

export default function ModalSuplement(props) {
    const [open, setOpen] = React.useState(false);
    
    const [nom, setNom] = React.useState(null);
    const [preu, setPreu] = React.useState(null);


    useEffect(() => {
     
    
    }, []);






    return (
        <React.Fragment>
           
            <Button
                variant="outlined"
                color="neutral"
                startDecorator={<Add />}
                onClick={() => setOpen(true)}
            >
                Agrega nou Suplement
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog className="modalPlat"
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        Crear un nou suplement
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        Omple els camps per crear un nou suplement
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();

                            fetch(process.env.REACT_APP_API_URL + "/nousuplement", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                },
                                body: JSON.stringify({
                                    nombre: nom,
                                    precio: preu,
                                }),
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    props.getSuplements();
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                                


                                 setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Nom del suplement</FormLabel>
                                <Input onChange={(e) => setNom(e.target.value)} autoFocus required />
                            </FormControl>
                         
                            <FormControl>
                                <FormLabel>Preu del suplement</FormLabel>
                                <Input onChange={(e) => setPreu(e.target.value)} autoFocus required />
                              
                            </FormControl>
                            
                            <Button type="submit">Crear suplement</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
           
            
        </React.Fragment>
    );
}