import { useEffect } from "react";
import { useState } from "react";
import { CartProvider, useCart } from "react-use-cart";


const ArticuloMenu = (articulo) => {
    const { addItem } = useCart();
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {


    }, []);


    const handleIncrement = () => {
        setQuantity(quantity + 1);
        let plat = {
            id: articulo.plat.menu + "M" + articulo.plat.id,
            name: articulo.plat.nombre,
            price: articulo.plat.precio,
            quantity: 1,
            tipo: articulo.plat.tipo,
            menu: articulo.plat.menu
        }
        addItem(plat);

    }
    const handleDecrement = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);

        }

    }

    {
        /*
  <div className="articulo__cantidad">
                <button className="articulo__cantidad-boton" onClick={handleDecrement}>-</button>
                <input type="number" className="articulo__cantidad-input" value={quantity} readOnly />
                <button className="articulo__cantidad-boton" onClick={handleIncrement}>+</button>
            </div>
        */
    }


    return (

        <div className="articulo">

            <div className="articulo__descripcion">

                <h3 className="titleInsideInside">{articulo.plat.nombre}</h3>
                <p className="subtitleInsideInside">{articulo.plat.descripcion}</p>
               
            </div>
            <div className="">
                {
                  //añade un icono de +
                  <button className="articulo__cantidad-boton">+</button>
                }

            </div>

        </div >
    );
}
export default ArticuloMenu;