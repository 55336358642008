import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from 'react-select';
import { useRef } from "react";


const Administrar = () => {
    const [tipoSeleccionado, setTipoSeleccionado] = useState(0);
    const [plats, setPlats] = useState([]);
    const [primers, setPrimers] = useState([]);
    const [segons, setSegons] = useState([]);
    const [postres, setPostres] = useState([]);
    const nomMenu = useRef(null);
    const preuMenu = useRef(null);
    const dataMenu = useRef(null);
    const [menus, setMenus] = useState([]);
    const [comandes, setComandes] = useState([]);

    useEffect(() => {

        fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
        })
            .then((response) => response.json())
           
            .then((data) => {
                if(data === true){
                    getPlats();
                    getMenus();
                }
                if(data === false){
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    window.location.href = "/login";
                }
            }
            );
        
      
        
    }, []);

    const crearMenuP = () => {

        let platos = [];

        platos.push(...primers.map((primer) => {
            return {
                "tipo": "Primer plat",
                "id_plato": primer.value,
                "cantidad_minima": 1
            }
        }));

        platos.push(...segons.map((segon) => {
            return {
                "tipo": "Segon plat",
                "id_plato": segon.value,
                "cantidad_minima": 1
            }
        }));

        platos.push(...postres.map((postre) => {
            return {
                "tipo": "Postre",
                "id_plato": postre.value,
                "cantidad_minima": 1
            }
        }));


        let menu = {
            nombre: nomMenu.current.value,
            precio: preuMenu.current.value,
            fecha: dataMenu.current.value,
            platos: JSON.stringify(platos)


        }
        const menuString = JSON.stringify(menu);

        fetch(process.env.REACT_APP_API_URL + "/crearmenu", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: menuString
        })
            .then((response) => response.json())
            .then((data) => {
                getMenus();
            }
            )
            .catch((error) => {
                console.log(error);
            }
            );

    }

    const getMenus = () => {
        fetch(process.env.REACT_APP_API_URL + "/menus", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setMenus(data);
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const eliminarMenu = (id) => {
        fetch(process.env.REACT_APP_API_URL + "/eliminarMenu/" + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })

            .then((response) => response.json())
            .then((data) => {
            
                getMenus();
            }
            )
            .catch((error) => {
                console.log(error);
            }
            );

    }

    const getComandes = () => {
        fetch(process.env.REACT_APP_API_URL + "/comandes", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                
                setComandes(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const getPlats = () => {
        fetch(process.env.REACT_APP_API_URL + "/plats", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPlats(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangeVista = (tipo) => {

        if(tipo === 2){
            getComandes();
        }

        setTipoSeleccionado(tipo);
    }  


    return (
        <div>
            <div className="headerTopB">
                <h1>Administrar</h1>
            </div>
            <div>
                <div className="subMenu">
                    <p onClick={() => handleChangeVista(0)} className={tipoSeleccionado === 0 ? "selected" : ""}>Menús</p>
                    <p onClick={() => handleChangeVista(1)} className={tipoSeleccionado === 1 ? "selected" : ""}>Plats</p>
                    <p onClick={() => handleChangeVista(2)} className={tipoSeleccionado === 2 ? "selected" : ""}>Comandes</p>
                </div>
                {
                    tipoSeleccionado === 0 ?
                        <div className="menus">
                            <div className="boxCrearMenu">
                                <div className="">
                                    <h4 className="txtC">Crear Menu</h4>
                                    <div className="formBox" >
                                        <div className="divDescripcionSelector">
                                            <p className="Textos">Nom del menu</p>
                                            <input className="placeholderCrearMenu" ref={nomMenu} type="text" placeholder="Nombre del menu" />
                                        </div>
                                        <div className="divDescripcionSelector">
                                            <p className="Textos">Preu del menu</p>
                                            <input className="placeholderCrearMenu" ref={preuMenu} type="number" placeholder="Precio del menu" />
                                        </div>
                                        <div className="divDescripcionSelector">
                                            <p className="Textos">Data del menu</p>
                                            <input className="placeholderCrearMenu" ref={dataMenu} type="date" placeholder="Data del menu" />

                                        </div>
                                        <div>

                                            <p className="Textos">Primer plat</p>
                                            <Select className="selectorPlats"
                                                value={primers}
                                                onChange={setPrimers}
                                                options={plats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                isMulti
                                            />
                                        </div>
                                        <div>
                                            <p className="Textos">Segon plat</p>
                                            <Select
                                                value={segons}
                                                onChange={setSegons}
                                                options={plats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                isMulti
                                            />
                                        </div>
                                        <div>

                                            <p className="Textos">Postre</p>
                                            <Select
                                                value={postres}
                                                onChange={setPostres}
                                                options={plats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                isMulti
                                            />
                                        </div>
                                        <div>
                                        </div>
                                        <button onClick={() => crearMenuP()} className="btnCrear">Crear menu</button>
                                    </div>



                                </div>
                            </div>
                            <div>

                                <div className="boxMenus">
                                    {menus &&
                                        menus.map((menu) => {
                                            return <div className="boxMenu">
                                                <p className="tituloMenu">{menu.nombre}</p>
                                                <p><span className="caracteristicasMenu">Data de comanda:</span> {menu.fecha}</p>
                                                <p><span className="caracteristicasMenu">Preu del menu:</span> {menu.precio}€</p>

                                                <div className="flexmenusfets">
                                                    <div>
                                                        <button className="btnMenusfets">Editar</button>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => eliminarMenu(menu.id)} className="btnMenusfets">Eliminar</button>
                                                    </div>


                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        : tipoSeleccionado === 1 ?
                            <div className="plats">
                                <div className="boxCrearPlats">
                                    <p>plats</p>




                                </div>

                            </div>
                            : tipoSeleccionado === 2 ?
                                <div className="comandes">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">email</th>
                                            <th scope="col">Nom</th>
                                            <th scope="col">Productes</th>
                                            <th scope="col">Telèfon</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Tipus Enviament</th>
                                            <th scope="col">Tipus Pagament</th>
                                            <th scope="col">Adreça</th>
                                            <th scope="col">Codi Postal</th>
                                            <th scope="col">Dia entrega</th>
                                            <th scope="col">Observacions</th>
                                            <th scope="col">ID Pagament </th>
                                            <th scope="col">Status</th>
                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {comandes &&
                                            comandes.map((comanda) => {
                                                return <tr>
                                                    <th scope="row">{comanda.id}</th>
                                                    <td>{comanda.data}</td>
                                                    <td>{comanda.email}</td>
                                                    <td>{comanda.nom}</td>
                                                    <td>{comanda.productes}</td>
                                                    <td>{comanda.telef}</td>
                                                    <td>{comanda.preu}€</td>
                                                    <td>{comanda.entrega}</td>
                                                    <td>{comanda.formapagament}</td>
                                                    <td>{comanda.adresa}</td>
                                                    <td>{comanda.cp}</td>
                                                    <td>{comanda.entrega}</td>
                                                    <td>{comanda.observacions}</td>
                                                    <td>{comanda.id_pagament}</td>
                                                    <td>{comanda.status_pagament}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>



                                </div>
                                : null
                }
            </div>
        </div>
    );
};
export default Administrar;