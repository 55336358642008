import React from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import BallotIcon from '@mui/icons-material/Ballot';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Star from '@mui/icons-material/Star';
import { Link } from "react-router-dom";
import "../index.css";

const LeftMenu = () => {
  const menuItems = [
    {
      text: "Menus",
      icon: <BallotIcon />,
      enlace: "/admin/menus",

    },
    {
      text: "Plats",
      icon: <RamenDiningIcon />,
      enlace: "/admin/plats",
    },
    {
      text: "Carta",
      icon: <MenuBookIcon />,
      enlace: "/admin/carta",
    },
    {
      text: "Comandes",
      icon: <RestaurantMenuIcon />,
      enlace: "/admin/comandes",
    },
    {
      text: "Suplements",
      icon: <PlaylistAddCheckIcon />,
      enlace: "/admin/suplements",
    },
    {
      text: "Sortejos",
      icon: <CardGiftcardIcon />,
      enlace: "/admin/sortejos",
    },
    {
      text: "Opinions",
      icon: <Star />,
      enlace: "/admin/opinions",
    },
  
  

  ];

  const handleCerrar = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    window.location.href = "/login";
  };

  return (
    <>
      <div>
        <Drawer  variant="permanent" className="menu-drawer">
      
          <Typography variant="h6" className="menu-title">
          <img src="https://feslatevacomanda.elracodelacigonya.com/fotos/raciIcon.png" alt="logo" className="logoRS" />
            <p className="RCTEXT">Racó de la Cigonya</p>
          </Typography>
          <List className="listadoMenu">
            {menuItems.map((item, index) => (
              <Link to={item.enlace} className="linkMenu">
              <ListItem button key={index}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText className="listMenuItem" primary={item.text} />
              </ListItem>
              </Link>
            ))}
            <ListItem button className="sign-out-item listMenuItem">
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText onClick={handleCerrar} primary="Sign out" />
            </ListItem>
          </List>
        </Drawer>
      </div>
    </>

  );
};

export default LeftMenu;