import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Option from '@mui/joy/Option';
import Textarea from '@mui/joy/Textarea';
import { FileUploader } from "react-drag-drop-files";
import NativeSelect from '@mui/material/NativeSelect';
import { useRef } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';

export default function ModalSoretig(props) {
    const [open, setOpen] = React.useState(false);
    const [formSorteig, setFormSorteig] = React.useState({
        nom: "",
        descripcio: "",
        quantitat: "",
        porcentatge: "",
    });


    useEffect(() => {
     

    }, []);




    return (
        <React.Fragment>
            
            <Button
                variant="outlined"
                color="neutral"
                startDecorator={<Add />}
                onClick={() => setOpen(true)}
            >
               Nou Sorteig
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog className="modalPlat"
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        Crear un nou soretig
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        Omple els camps per crear un nou sorteig
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            //console confirm 
                         
                            let form = {
                                nombre: formSorteig.nom,
                                descripcion: formSorteig.descripcio,
                                cantidad: parseInt(formSorteig.quantitat),
                                porcentaje: parseInt(formSorteig.porcentatge),
                            };
                            fetch(process.env.REACT_APP_API_URL + "/nousorteig", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: 
                                    JSON.stringify(form)
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    props.getAllSorteigs();
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            setOpen(false);
                        }}
                    >

                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Que conté el sorteig</FormLabel>
                                <Input autoFocus required  onChange={(event) => setFormSorteig({ ...formSorteig, nom: event.target.value })} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Quina quantitat</FormLabel>
                              <input className="defInp" type="number" min="1" max="100" onChange={(event) => setFormSorteig({ ...formSorteig, quantitat: event.target.value })} required/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Petita descripcio del sorteig</FormLabel>
                                <Textarea required onChange={(event) => setFormSorteig({ ...formSorteig, descripcio: event.target.value })} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Quin porcentatge vols que tingui el premi 0-100%</FormLabel>
                                <input className="defInp" type="number" min="1" max="100" onChange={(event) => setFormSorteig({ ...formSorteig, porcentatge: event.target.value })} required/>
                            </FormControl>
                           
                            <Button type="submit">Crear Sorteig</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
            
        </React.Fragment>
    );
}