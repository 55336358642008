import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Textarea from '@mui/joy/Textarea';
import { FileUploader } from "react-drag-drop-files";
import NativeSelect from '@mui/material/NativeSelect';

export default function ModalPlat(props) {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [nom, setNom] = React.useState(null);
    const [tipus, setTipus] = React.useState(null);
    const [descripcio, setDescripcio] = React.useState(null);
    const [preu, setPreu] = React.useState(null);
    const fileTypes = ["JPG"];




    const handleChange = (file) => {

        //setFile(file.target.files[0]);
        let fileData = {
            name: file.name.split("."),
            file: file,
        }
        console.log(fileData);
        const formData = new FormData();
        formData.append('file', file);

        fetch(process.env.REACT_APP_API_URL + "/subirimagen", {
            method: "POST",

            body: formData,
        })

            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            }
            );



        setFile(fileData);



    };



    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="neutral"
                startDecorator={<Add />}
                onClick={() => setOpen(true)}
            >
                Agrega nou plat
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog className="modalPlat"
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        Crear nou plat
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        Omple els camps per crear un nou plat
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();

                            if (tipus == 0) {
                                alert("Selecciona un tipus de plat");
                                return;
                            }

                            let form = {
                                nom: nom,
                                tipus: tipus,
                                descripcio: descripcio,
                                preu: preu,
                                imatge: file !== null ? file.name : null,

                            }

                            fetch(process.env.REACT_APP_API_URL + "/crearplat", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(form),
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.succes) {
                                        alert("Plat creat correctament");
                                        setOpen(false);
                                        setNom(null);
                                        setTipus(null);
                                        setDescripcio(null);
                                        setPreu(null);
                                        setFile(null);

                                        // Llama a la función getAllPlats del padre 
                                        props.getAllPlats();
                                    }
                                });



                            //     setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Nom</FormLabel>
                                <Input onChange={(e) => setNom(e.target.value)} autoFocus required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Tipus de plat</FormLabel>
                                <NativeSelect
                                    defaultValue={0}
                                    onChange={(e) => setTipus(e.target.value)}
                                    required
                                    inputProps={{
                                        name: 'tipusplat',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={0}>Selecciona una opció</option>
                                    <option value={1}>Primer plat</option>
                                    <option value={2}>Segon plat</option>
                                    <option value={3}>Postres</option>
                                </NativeSelect>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Descripció</FormLabel>
                                <Textarea onChange={(e) => setDescripcio(e.target.value)} minRows={2} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Puja una fotografia</FormLabel>
                                <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                                {
                                    file !== null ? <span className='uploadedTxt'>Fitxer seleccionat: {file.name[0]}</span> : null
                                }
                            </FormControl>
                            <FormControl>
                                <FormLabel>Preu</FormLabel>
                                <Input
                                    
                                    autoFocus
                                    required
                                    onChange={(e) => setPreu(e.target.value)}
                                />
                            </FormControl>

                            <Button type="submit">Crear plat</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}