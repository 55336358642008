import Button from '@mui/joy/Button';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__container__logo">
                    <img src="https://feslatevacomanda.elracodelacigonya.com/fotos/footer.jpg" alt="logo" />
                </div>
                <div className="footer__container__logo2">
                    <img src="https://feslatevacomanda.elracodelacigonya.com/fotos/raco.jpg" alt="logo" />
                </div>

            </div>
            <div className="footer__container">
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_Avis_legal.pdf" download>
                    <span className="footer__container__txt">Avis legal</span>-
                </a>
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_Clausules_informatives_en_formularis_web_(PD_WEB_FORM).pdf" download>
                    <span className="footer__container__txt">Clauseles informatives</span>-
                </a>
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_E-COMMERCE_(ALIMENTS-BENS_PERIBLES)_Condicions_generals_de_contractac.pdf" download>
                    <span className="footer__container__txt">Condicions generals de contractació</span>-
                </a>
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_E-COMMERCE_(INFO)_Informacio_sobre_FULLS_DE_RECLAMACIONS.pdf" download>
                    <span className="footer__container__txt">Full de reclamacions</span>-
                </a>
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_E-COMMERCE_Formulari_de_sol·licitud_de_DESISTIMENT_(consumidor-usuari.pdf" download>
                    <span className="footer__container__txt">Sol·licitud de desestiment</span>-
                </a>
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_E-COMMERCE_Resolucio_de_litigis_en_linia_(Online_Dispute_Resolution).pdf" download>
                    <span className="footer__container__txt">Online Dispute Resolution</span>-
                </a>
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_FORMULARI__Consentiment_per_al_tractament_de_dades.pdf" download>
                    <span className="footer__container__txt">Consentiment per al tractament de dades</span>-
                </a>
                <a target="_blank" href="https://feslatevacomanda.elracodelacigonya.com/legal/EL_RACO_DE_LA_CIGONYA_SLU_WEB__Politica_de_PRIVACITAT.pdf" download>
                    <span className="footer__container__txt">Política de PRIVACITAT</span>-
                </a>
            </div>
            {
                /*
                 <Button
                variant="outlined"
                color="neutral"

                onClick={() => setOpen(true)}
            >
                Edita el plat
            </Button>
                */

            }
        </footer>



    );
}
export default Footer;