
import LeftMenu from './components/menu.js';
import { useEffect } from 'react';
import { useState } from 'react';
import ModalCarta from './components/addPlatCartaModal.js';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Carta = () => {
    const [platsCarta, setPlatsCarta] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleRefresh = () => {
        setRefresh(!refresh);
    }


    useEffect(() => {

        fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
        })
            .then((response) => response.json())

            .then((data) => {
                if (data === true) {
                    getPlatsCarta();
                    getvisibilitatcarta();
                }
                if (data === false) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    window.location.href = "/login";
                }
            }
            );



    }, [refresh]);


    const getvisibilitatcarta = () => {
        fetch(process.env.REACT_APP_API_URL + "/visibilitatcarta", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setVisible(data.visibilitat);
                
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const getPlatsCarta = () => {
        fetch(process.env.REACT_APP_API_URL + "/platscarta", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPlatsCarta(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };





    return (
        <div className="menu-container">

            <LeftMenu />
            <div className='content'>
                <h1>Carta</h1>
                <div>
                    <p className='cartaWebTxt'> Mostrar carta a la web
                        <Checkbox
                            defaultChecked
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={visible}
                            onChange={() => {
                                fetch(process.env.REACT_APP_API_URL + "/modificarvisibilitatcarta", {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({ visibilitat: !visible }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        getvisibilitatcarta();
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }}
                            
                        />
                    </p>

                </div>

                <div className='gridPlats'>
                    <ModalCarta onRefresh={handleRefresh} />

                </div>
                <br></br>
                <div className='gridPlats'>
                    {platsCarta.map((plat) => (
                        <Card variant="outlined" sx={{ width: 320 }}>
                            <Typography className="titleBox2" level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                                {plat.nombre}
                            </Typography>
                            <Typography className="tipusPlat" level="body2">Plat de carta</Typography>
                            <Typography className="descripcioPlat" level="body2" fontSize="sm" sx={{ mb: 1.5 }}>
                                {plat.descripcion}
                            </Typography>
                            <IconButton
                                variant="plain"
                                color="neutral"
                                size="sm"
                                sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
                            >
                                <DeleteIcon style={{ color: "#e77878" }}
                                    onClick={() => {
                                        fetch(process.env.REACT_APP_API_URL + "/deleteplatcarta", {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: JSON.stringify({ id: plat.id }),
                                        })
                                            .then((response) => response.json())
                                            .then((data) => {
                                                getPlatsCarta();
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }}

                                />
                            </IconButton>


                            <Box sx={{ display: 'flex' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedB"
                                            checked={plat.minim}
                                            onChange={() => {

                                                fetch(process.env.REACT_APP_API_URL + "/minimplat", {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    },
                                                    body: JSON.stringify({ id: plat.id, minim: !plat.minim }),

                                                })
                                                    .then((response) => response.json())
                                                    .then((data) => {
                                                        getPlatsCarta();
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                            }}

                                        />
                                    }
                                    label="Aquest plat sera com a minim per dos persones"
                                />


                            </Box>
                        </Card>
                    ))}
                </div>
            </div>

        </div>
    )
}
export default Carta